<template>
  <div class="main-wrapper">
    <el-scrollbar class="main-scrollbar" :native="false">
      <div class="main-wrapper-header">
        <div class="header-left">
          <el-breadcrumb separator=">" style="margin-left: 4px" >
            <el-breadcrumb-item>考试训练</el-breadcrumb-item>
            <el-breadcrumb-item>{{$route.query.trainName}}</el-breadcrumb-item>
            <el-breadcrumb-item>第{{numStr}}次</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="header-right">
          <el-button @click="toBack" type="primary">返回</el-button>
        </div>
      </div>
      <StudentTrainAnswerDetail v-if="Object.keys(answerData).length > 0" :answerData="answerData" />
      <div v-else style="text-align: center;margin-top: 40px">暂无数据</div>
    </el-scrollbar>
  </div>
</template>

<script>
import {trainAnswerDetailStudent} from '@/utils/apis'
import StudentTrainAnswerDetail from '@/components/student/StudentTrainAnswerDetail.vue'
export default {
  name: "PaperDetail",
  components:{
    StudentTrainAnswerDetail
  },
  data(){
    return {
      numStr:this.$route.query.numStr || null,
      answerData:{}
    }
  },
  mounted() {
    this.getTrainAnswerDetailStudent()
  },
  methods:{
    getTrainAnswerDetailStudent(){
      let params = {
        student_theory_train_log_id:this.$route.query.logId
      }
      trainAnswerDetailStudent(params).then((res)=>{
        for(let i in res.data.bottom_data){
          res.data.bottom_data[i].list.forEach(item=>{
            this.$set(item, 'isAnalysisShow', false)
          })
        }
        this.answerData = res.data;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    toBack(){
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-breadcrumb{
  .el-breadcrumb__item{
    .el-breadcrumb__inner{
      color: #999999;
    }
    &:last-of-type{
      .el-breadcrumb__inner{
        color: #333;
      }
    }
  }
  .el-breadcrumb__separator{
    color: #999;
    font-weight: 500;
  }
}
.main-wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-scrollbar{
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
      .el-scrollbar__view{
        padding: 0 20px;
      }
    }
  }
  .main-wrapper-header{
    padding: 0px 0 14px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #F1F5FF;
    .header-left{
      display: flex;
      align-items: center;
      span{
        line-height: 1;
      }
    }
  }
}
</style>